import { useState, useMemo, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  message,
  Checkbox,
  Radio,
  Space,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import axios from "axios";

import {
  getApiErrorMsg,
  getApiResponseMsg,
} from "../../../../utils/object-util";
import { useQuery } from "../../../../hooks/useQuery";

import { Input } from "../../../../components/form-fields/input";
import { TextArea } from "../../../../components/form-fields/text-area";
import { isArray } from "../../../../utils/type-util";
import { useAppContext } from "../../../../components/contexts/appContext";
import { IEventDetails, IEventQuestion } from "../../../../interfaces/events";

import styles from "./timeSlotConfirmationForm.module.scss";
import { profileTokenKey } from "../../../../constants";

const { Item } = Form;

interface ITimeSlotConfirmationProps {
  event: IEventDetails;
  userlink: string;
  eventlink: string;
  // selectedTime: string;
  selectedDate: any;
  timezone: string;
  selectedSlotData: { slotTime: string };
  userData: {
    // From autoscal
    name: string | null;
    email: string | null;
  };
}

dayjs.extend(utc);
dayjs.extend(timezone);

const TimeSlotConfirmationForm = ({
  event,
  userlink,
  eventlink,
  // selectedTime,
  selectedDate,
  timezone,
  selectedSlotData,
  userData,
}: ITimeSlotConfirmationProps) => {
  const [callingApi, setCallingApi] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { query } = useQuery();
  const { isAutoscalLandingPage, isProfilePage, profileToken } =
    useAppContext();

  const eventQuestions = useMemo(
    () => event.questions.filter((el: IEventQuestion) => el.isActive),
    [event]
  );

  // Initialize the initial form values with preselected data

  const initialFormValues = useMemo(() => {
    const initialValues: { [key: string]: any } = {};
    eventQuestions.forEach((question: any) => {
      if (question.type === "Checkboxes") {
        initialValues[question.id] = question.defaultValue.split(",");
      } else {
        initialValues[question.id] = question.defaultValue || "";
      }
    });
    return initialValues;
  }, [eventQuestions]);

  useEffect(() => {
    if (userData.name && userData.email) {
      form.setFieldsValue(userData);
    }
  }, [userData]);

  const confirmInterview = async (data: any) => {
    setCallingApi(true);
    try {
      const { name, email, ...rest } = data;
      for (const [key, value] of Object.entries(rest)) {
        if (isArray(value)) {
          const newVal: any = value;
          rest[key] = newVal.join(",");
        }
      }
      let profileUrl = "";
      if (profileToken) {
        localStorage.setItem(profileTokenKey, profileToken);
        profileUrl = `/users/${userlink}/events/${eventlink}/bookings`;
      }

      const response = await axios.post(
        isProfilePage
          ? profileUrl
          : `public/users/${userlink}/events/${eventlink}/bookings`,
        {
          slotTime: selectedSlotData.slotTime,
          inviteeResponse: { ...rest },
          customerName: name,
          customerEmail: email,
          timezone,
        }
      );
      localStorage.removeItem(profileTokenKey);
      if (!isProfilePage) {
        message.success({
          content: getApiResponseMsg(response),
          key: "success",
          duration: 2,
        });
      }
      if (response.data?.data?.payment?.checkoutUrl) {
        window.open(response.data.data.payment.checkoutUrl, "_self");
      } else {
        // Navigate to payments page. Code will not be available so handle there accordingly.
        if (window.parent) {
          const dataToSend = {
            meeting: response.data.data.meeting,
            slotTime: response.data.data.slotTime,
            isConfirmed: true,
            source: "Calendar App",
          };
          window.parent.postMessage(dataToSend, "*");
        }

        const path = `/payments${isProfilePage ? `?${query.toString()}` : ""}`;
        navigate(path, {
          state: {
            details: {
              customerName: name,
              customerEmail: email,
              event: {
                name: event.name,
                link: event.link,
                user: { link: userlink },
              },
              slotTime: response.data.data.slotTime,
              timezone: response.data.data.timezone,
            },
          },
        });
      }
    } catch (err) {
      message.error({
        content: getApiErrorMsg(err),
        key: "error",
        duration: 2,
      });
      setCallingApi(false);
    } finally {
      setCallingApi(false);
    }
  };

  const selectDate = dayjs(selectedSlotData?.slotTime)
    .tz(timezone)
    // .format("ddd, Do MMM YYYY hA z");
    .format(`ddd, Do MMM YYYY h:mm A [${dayjs().tz(timezone).format("z")}]`);

  const isProfileSelectedDate = dayjs(selectedSlotData?.slotTime)
    .tz(timezone)
    // .format("ddd, Do MMM YYYY hA z");
    .format(`ddd, Do MMM YYYY`);

  const isProfileSelectedTimeZone = dayjs(selectedSlotData?.slotTime)
    .tz(timezone)
    .format(`h:mm A [${dayjs().tz(timezone).format("zzz")}]`);

  const isSelectedDate = dayjs(selectedDate, "DD-MM-YYYY").format(
    "ddd, Do MMM YYYY"
  );

  return (
    <div
      className={`${styles["wrapper"]} ${
        isAutoscalLandingPage && styles["wrapper-landing"]
      } ${isProfilePage && styles["wrapper-profile"]}`}
    >
      <h3 className={styles["title"]}>
        {`${
          isAutoscalLandingPage
            ? "Let us know about you:"
            : isProfilePage
            ? `${selectedDate ? isSelectedDate : isProfileSelectedDate} ${
                selectedSlotData ? `| ${isProfileSelectedTimeZone}` : ""
              }`
            : "Enter Details"
        }`}
      </h3>
      <Row>
        <Col xs={24} className={styles["main-col"]}>
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={confirmInterview}
            form={form}
            initialValues={initialFormValues} // Set initial form values here
          >
            <Item
              name="name"
              label="Name"
              hidden={isProfilePage}
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input disabled={!!userData.name} />
              {/* Using user data for disabled condition because userData is sent only when 
              interview is being booked from autoscal */}
            </Item>

            <Item
              name="email"
              label="Email"
              hidden={isProfilePage}
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input type="email" disabled={!!userData.email} />
            </Item>

            {eventQuestions.map((question: any) => (
              <GetQuestion question={question} key={question.id} />
            ))}

            {selectedSlotData && (
              <div className={styles["schedule-btn-wrapper"]}>
                <Button htmlType="submit" loading={callingApi} type="primary">
                  {isAutoscalLandingPage
                    ? "Schedule call"
                    : isProfilePage
                    ? "Request Call"
                    : "Confirm"}
                </Button>
                {isAutoscalLandingPage && (
                  <p className={styles["selected-date"]}>{selectDate}</p>
                )}
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const GetQuestion = ({ question }: any) => {
  const defaultValue = question.defaultValue;
  const { isAutoscalLandingPage } = useAppContext();
  const placeholder = question.placeholder || "";
  const type = question.type;
  const getInput = () => {
    if (type === "OneLine") {
      if (isAutoscalLandingPage) {
        return <Input placeholder={placeholder} type="number" />;
      } else {
        return <Input placeholder={placeholder} />;
      }
    } else if (type === "MultipleLines") {
      return (
        <TextArea
          rows={4}
          col={3}
          placeholder={placeholder}
          className={styles["text-are-profile"]}
        />
      );
    } else if (type === "Checkboxes") {
      return (
        <Checkbox.Group>
          <Space direction="vertical">
            {question.options.map((option: any) => {
              const isChecked = defaultValue && defaultValue.includes(option);
              return (
                <Checkbox
                  value={option}
                  key={option}
                  defaultChecked={isChecked}
                >
                  {option}
                </Checkbox>
              );
            })}
          </Space>
        </Checkbox.Group>
      );
    } else if (type === "RadioButtons") {
      return (
        <Radio.Group defaultValue={defaultValue}>
          <Space direction="vertical">
            {question.options.map((option: any) => {
              return (
                <Radio value={option} key={option}>
                  {option}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      );
    } else if (type === "Dropdown") {
      return (
        <Select
          defaultValue={defaultValue}
          options={question.options.map((option: any) => ({
            label: option,
            value: option,
          }))}
        />
      );
    }
  };

  // Add required rule based on the isRequired field
  const rules = question.isRequired
    ? [
        {
          required: true,
          message: `${
            isAutoscalLandingPage
              ? "Please enter description"
              : "Answer Required"
          }`,
        },
      ]
    : [];

  return (
    <Item
      name={question.id}
      label={`${question.question} ${!question.isRequired ? "(optional)" : ""}`}
      rules={rules}
      colon={false}
    >
      {getInput()}
    </Item>
  );
};

export default TimeSlotConfirmationForm;
